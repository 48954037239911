import * as THREE from "three";
// import ColorTween from "color-tween";
import particleBuilder from "./utils/particleBuilder";
import gsap from "gsap";

// (() => {
//
// 	if (navigator.userAgent.indexOf('iPhone') !== -1) {
// 		return;
// 	}
//
// 	let lastScroll = 0;
// 	let lastSection = false;
// 	let awaitScroll = false;
// 	let advantages_sections = document.querySelectorAll( '#advantages-container-tmp > *' );
// 	let header = document.querySelector( '.fw-header' );
// 	let last_section = advantages_sections[advantages_sections.length-1];
//
// 	let scrollDocument = ( el, dir ) => {
// 		let lerp = ( start, end, rate ) => {
// 			return (1 - rate) * start + rate * end;
// 		};
//
// 		let scrolling = () => {
// 			let dist = Math.round( lerp( window.scrollY, scrollto, 0.1 ) );
//
// 			if ( dir === 'vertical' ) {
// 				window.scrollTo( 0, dist );
// 			} else {
// 				dist = Math.round( lerp( parent.scrollLeft, scrollto, 0.1 ) );
// 				parent.scrollLeft = dist;
// 				parent.classList.add( 'fw--is-scrolling' );
// 			}
//
// 			if ( lastscroll !== dist ) {
// 				lastscroll = dist;
// 				window.requestAnimationFrame(
// 					() => {
// 						scrolling( el, dir );
// 					}
// 				);
// 			} else {
// 				window.scrollTo( 0, scrollto );
// 				setTimeout(
// 					() => {
// 						parent.classList.remove( 'fw--is-scrolling' );
// 						document.body.style.overflow = 'auto';
// 					},
// 					1000
// 				)
//
// 				el.dispatchEvent(
// 					new CustomEvent(
// 						'anchorScrollEnd'
// 					)
// 				);
// 			}
// 		};
//
// 		let navh = 0;//document.querySelector('.fw-header').offsetHeight;
// 		let scrollto = el.offsetTop;
// 		let lastscroll = window.scrollY;
// 		let parent = el.offsetParent;
//
// 		if ( dir === 'vertical' ) {
// 			while ( el.offsetParent ) {
// 				el = el.offsetParent;
// 				scrollto += el.offsetTop;
// 			}
// 		} else {
// 			scrollto += el.offsetLeft;
// 		}
//
// 		if ( dir === 'vertical' ) {
// 			scrollto -= navh;
// 		}
//
// 		document.body.style.overflow = 'hidden';
//
// 		el.dispatchEvent(
// 			new CustomEvent(
// 				'anchorScrollStart'
// 			)
// 		);
// 		window.requestAnimationFrame( scrolling );
// 	};
//
// 	setInterval(
// 		() => {
// 			let pageY = window.scrollY;
// 			let pageH = window.innerHeight;
// 			let scrollToEl = false;
//
// 			if (
// 				pageY === lastScroll ||
// 				pageY < 0 ||
// 				(
// 					pageY > lastScroll && pageY+pageH > last_section.offsetTop + last_section.offsetHeight ||
// 					pageY < lastScroll && pageY > last_section.offsetTop + last_section.offsetHeight ||
// 					pageY < lastScroll && pageY < advantages_sections[0].offsetTop
// 				)
// 				||
// 				! document.documentElement.classList.contains( 'fw--is-scrolled' ) ||
// 				document.querySelector( '.fw--is-scrolling' )
// 			) {
//
// 				// window.console.log( 'Ignore' );
//
// 				if (
// 					pageY > lastScroll && pageY+pageH > last_section.offsetTop + last_section.offsetHeight ||
// 					pageY < lastScroll && pageY < advantages_sections[0].offsetTop
// 				) {
// 					// window.console.log( 'Clearing active section' );
// 					lastSection = false;
// 				}
//
// 				lastScroll = window.scrollY;
// 				return;
// 			}
//
// 			if ( pageY > lastScroll ) {
// 				for ( let i = 0; i < advantages_sections.length; i++ ) {
// 					if ( pageY + pageH > advantages_sections[i].offsetTop ) {
// 						scrollToEl = advantages_sections[i];
// 					}
// 				}
// 				// window.console.log( 'Scroll to Next: ' + scrollToEl.querySelector( 'h2' ).innerHTML );
//
// 			} else {
// 				for ( let i = 0; i < advantages_sections.length; i++ ) {
// 					if ( pageY < advantages_sections[i].offsetTop + advantages_sections[i].offsetHeight - 30 ) {
// 						scrollToEl = advantages_sections[i];
// 						break;
// 					}
// 				}
// 				// window.console.log( 'Scroll to Previous: ' + scrollToEl.querySelector( 'h2' ).innerHTML );
// 			}
//
// 			lastScroll = window.scrollY;
//
// 			if ( lastSection === scrollToEl ) {
// 				// window.console.log( 'Already scrolled.' );
// 				return;
// 			}
//
// 			scrollDocument( scrollToEl, 'vertical' );
//
// 			lastSection = scrollToEl;
// 		},
// 		1
// 	);
//
// })();

let colors = [
	'#dce442',
	'#72C1C6',
	'#93C159',
	'#C9FFD5'
];

let index = 0;
let mediaElements = [];
let sections = []

window.fw3jspause = true;

if ( document.querySelector( '#advantages-container-tmp' ) ) {
	document.querySelectorAll( '#advantages-container-tmp > .wp-block-group' ).forEach(
		( section ) => {
			let img_src = section.querySelector( '.wp-block-cover__image-background' ).getAttribute( 'src' );
			section.querySelector( '.wp-block-cover__background' ).remove();
			section.querySelector( '.wp-block-cover__image-background' ).remove();

			section.setAttribute(
				'data-section',
				JSON.stringify(
					{
						'image': img_src,
						'color' : colors[index]
					}
				)
			);

			// Used for Observer
			sections.push(section)
			// Used for particleBuilder
			mediaElements.push(img_src)

			index++;
			if ( index >= colors.length ) {
				index = 0;
			}
		}
	);


	// Set the default color of our first section
	const defaultColor = JSON.parse(sections[0].dataset.section).color;

	// Create our Particles
	const advantages = new particleBuilder("#advantages-container-tmp", {
	  color: defaultColor, //  Default color - can be set with [data-secion-hex]
	  screen: true,
	  images: mediaElements ?? []
	});

	// Observer for swapping colors as you scroll
	let lastIndex = 0;
	let observeConfig = {
		root: null,
		rootMargin: "0px",
		threshold: 0.5
	};
	let observer = new IntersectionObserver((entries) => {
		let hasIntersection = false;
		entries.forEach((entry) => {
			entry.target.classList.remove("section-active");
			if (entry.isIntersecting) {
				hasIntersection = true;
				window.fw3jspause = false;
				entry.target.classList.add("section-active");
				const index = mediaElements.findIndex(
					(i) => i === JSON.parse(entry.target.dataset.section).image
				);
				if (advantages.visualizer.particles.material) {

					// Set this for our color tween use
					let previousEntry = sections[lastIndex];

					updateColor(JSON.parse(previousEntry.dataset.section).color, JSON.parse(entry.target.dataset.section).color)

					// GSAP animation to tween colors
					function updateColor(initial, updated) {
						initial = new THREE.Color(initial)
						updated = new THREE.Color(updated)
						gsap.to(initial, 1.5, {
							r: updated.r,
							g: updated.g,
							b: updated.b,

							onUpdate: function () {
								advantages.visualizer.particles.object3D.material.uniforms.hexColor.value = initial.getHex();
								advantages.visualizer.particles.object3D.material.uniforms.uColor.value = new THREE.Vector4(
									initial.r,
									initial.g,
									initial.b,
									1.0
								);
								advantages.visualizer.particles.object3D.material.needsUpdate = true;
							}
						  })

					}
				}

				// Go to next index section
				advantages.visualizer.goto(index);
				// Store for later
				lastIndex = index;

			} else {
				// Update material just in case...
				// advantages.visualizer.particles.material.needsUpdate = true;
			}
		});
		if ( ! hasIntersection ) {
			window.fw3jspause = true;
		} else {
			window.fw3jspause = false;
		}
	}, observeConfig);

	// Observe all of our sections
	for (const sec of sections) {
		observer.observe(sec);
	}

}
